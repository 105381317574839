import React from "react";
import { Row, Col } from "antd";
import loadable from "@loadable/component";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import {
  emailValidationRegExp,
  phoneValidationRegExp,
  formItemTypes,
} from "../../../components/GatedAsset/constants";
import ogImage from "../../../images/global_assets/og-image.png";
import { gatedSectionsv6 } from "../../../data/landing/gated-v2";
import { lineOfBusiness, zipValidationRegExp } from "../../../constants";
// import GatedForm from "../../../components/CustomGated/GatedForm";
// import Image from "../../../components/ImageQuerys/LargeFeaturesImages";
// import GatedCTA from "../../../components/CustomGated/GatedCTA";
// import GatedSpecial from "../../../components/CustomGated/GatedSpecial";
// import GatedSellingPoint from "../../../components/CustomGated/GatedSellingPoint";

const GatedForm = loadable(() =>
  import("../../../components/CustomGated/GatedForm")
);
const Image = loadable(() =>
  import("../../../components/ImageQuerys/LargeFeaturesImages")
);
const GatedCTA = loadable(() =>
  import("../../../components/CustomGated/GatedCTA")
);
const GatedSpecial = loadable(() =>
  import("../../../components/CustomGated/GatedSpecial")
);
const GatedSellingPoint = loadable(() =>
  import("../../../components/CustomGated/GatedSellingPoint")
);

const formDataToSend = ["firstname", "email", "phone", "company", "get_demo"];

const subtext = "";
const blockList = [
  "Content feature 1",
  "Content feature 2",
  "Content feature 3",
  "Content feature 4",
];

const data = [
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "First Name",
    name: "firstName",
    rules: [
      {
        required: true,
        message: "Please enter your first name",
        min: 2,
      },
    ],
    placeholder: "Your First Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Last Name",
    name: "lastName",
    rules: [
      {
        required: true,
        message: "Please enter your last name",
        min: 2,
      },
    ],
    placeholder: "Your Last Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "email",
    label: "Email",
    name: "email",
    rules: [
      {
        required: true,
        message: "Please enter a valid email",
        pattern: emailValidationRegExp,
      },
    ],
    placeholder: "e.g. john_doe@mybusiness.com",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Business Name",
    name: "company",
    rules: [
      {
        required: true,
        message: "Please enter your business name",
        min: 3,
      },
    ],
    placeholder: "e.g. MyBusiness Inc. ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "tel",
    label: "Business Phone",
    name: "phone",
    rules: [
      {
        required: true,
        message: "Please enter your phone",
        pattern: phoneValidationRegExp,
      },
    ],
    placeholder: "e.g. (555) 555-1243 ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: null,
    label: "Zip Code",
    name: "zip",
    rules: [
      {
        required: true,
        message: "Please enter your zip code",
        min: 4,
        pattern: zipValidationRegExp,
      },
      // () => ({
      //   validator(_, value) {
      //     const address = zipCodesMap.get(value);
      //     if (address && address.zip_code) {
      //       return Promise.resolve();
      //     }
      //     return Promise.reject("Please enter a valid zip code");
      //   },
      // }),
    ],
    placeholder: "e.g. 10001",
    visible: true,
  },
  {
    itemType: formItemTypes.CHECKBOX,
    itemSubType: null,
    label: "Yes, I’d like a demo of SpotOn Restaurant point-of-sale.",
    name: "get_demo",
    visible: true,
  },
];

const GatedAssetv1 = () => {
  return (
    <LpLayout phoneNumber="+18775594225" phoneText="(877) 559-4225">
      <SEO
        title="SpotOn Gated Assets | Point-of-Sale E-Book"
        description="Boost efficiency, increase sales, and keep guests coming back with a single point-of-sale solution for in-person orders, Order with Google, and online ordering"
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <Row className="gate-heading-v1" gutter={[32, 32]} align="middle">
        <Col lg={12}>
          <div>
            <Heading
              injectionType={2}
              level={1}
              symbol="."
              className="text-white"
              withBlueSymbol
            >
              Build a better guest experience & maximize revenue with
              self-service
            </Heading>
            <p className="gated-intro-para cta-light">
              The days of long lines, paper tickets, and counting change at cash
              registers are gone. Get our white paper and learn how self-service
              technology can help your venue or facility streamline operations,
              get better data, increase revenue, and serve more guests despite
              labor shortages.
            </p>
          </div>
        </Col>
        <Col lg={12}>
          <div className="gated-form-cont-v3">
            <GatedForm
              pageTitle="Title of the downloadable content"
              pageSubtext={subtext}
              btnText="Submit"
              formTitle="Download your free whitepaper"
              formId="c3221775-397e-41fe-ac5f-f822c36d96df"
              formItems={data}
              formDataToSend={formDataToSend}
              featureTitle="What you get"
              featureSubtext="Fill out the form to get your free download today."
              blockList={blockList}
              topImg="asset-download.png"
              thankYouPath="/gated-assets/better-guest-experience-and-maximize-profits/thank-you"
              gatedCampaign="better-guest-xp"
              lineOfBusiness={lineOfBusiness.VENUES}
            />
          </div>
          <div className="form-bg">
            <Image imageName="Form-Bg.png" />
          </div>
        </Col>
        <Col lg={24}>
          <Image className="banner-text" imageName="banner-text.png" />
        </Col>
      </Row>
      <Row gutter={[32, 32]}>
        <Col lg={24} className="w-full">
          <GatedSpecial sectionData={gatedSectionsv6} />
        </Col>

        <Col lg={24}>
          <GatedSellingPoint
            mainPoint="Reduce overhead and increase sales by going self-service"
            mark="."
            subtext="With today’s changing guest expectations and anemic labor market, technology is crucial in helping your enterprise maintain a healthy profit margin. Self-service technology, in particular, is a powerful tool that creates a better experience and boosts efficiency for both retail and food & beverage transactions."
            withCta={false}
            // ctaTitle="Download the free whitepaper"
            // ctaTarget="/SpotOn_Self_Service.pdf"
          />
        </Col>
      </Row>

      <Row
        gutter={[32, 32]}
        className="mt-10 lg:mt-20 mb-20 lg:mb-32 px-4 lg:px-0"
      >
        <Col xs={24}>
          <Image imageName="gated-mp-footer.png" />
        </Col>
      </Row>
    </LpLayout>
  );
};

export default GatedAssetv1;
